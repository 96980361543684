import React from "react"
import { Card } from "react-bootstrap"

const Index = ({ title, image, onClick }) => {
  let sortTitle = title
  return (
    <div className="p-3">
      <Card
        style={{
          margin: "10px auto",
          height: 320,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Card.Img
          variant="top"
          style={{
            objectFit: "cover",
            display: "block",
          }}
          height={220}
          src={`${
            image ? image : "../../../../images/Berita_default-image.png"
          }`}
          className="card-img-technopark"
        />
        <Card.Body>
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex flex-column h-100">
              <span className="technopark-title-content" style={{ fontSize: 20, fontWeight: "bold" }}>
                {sortTitle}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Index
