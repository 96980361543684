import React, { useEffect, createRef } from "react"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/themes/splide-default.min.css"
const Index = ({ data }) => {
  const primaryRef = React.createRef()
  const secondaryRef = React.createRef()

  const primaryOptions = {
    type: "loop",
    perPage: 1,
    perMove: 1,
    gap: "1rem",
    pagination: false,
  }

  const secondaryOptions = {
    type: "slide",
    rewind: true,
    gap: "1rem",
    pagination: false,
    fixedWidth: 110,
    fixedHeight: 70,
    cover: true,
    focus: "center",
    isNavigation: true,
    updateOnMove: true,
  }

  useEffect(() => {
    primaryRef.current.sync(secondaryRef.current.splide)
  }, [])
  const renderSlides = data => {
    return data.map((slide, idx) => (
      <SplideSlide key={slide.src}>
        <img
          className="img-chosen-detail-technopark"
          src={slide}
          alt={`img-${idx}`}
        />
      </SplideSlide>
    ))
  }
  return (
    <>
      <Splide options={primaryOptions} ref={primaryRef}>
        {renderSlides(data)}
      </Splide>
      <div className="my-3" />
      <Splide options={secondaryOptions} ref={secondaryRef}>
        {renderSlides(data)}
      </Splide>
    </>
  )
}

export default Index
